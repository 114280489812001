export const onClientEntry = () => {
	console.log(
		'%cRuth Dillon-Mansfield',
		'display: block; color: #c1002e; font-size: 35px; font-family: "Trebuchet MS"'
	);
	console.log(
		'%cPorfolio and Blog',
		'display: block; color: #c1002e; font-size: 20px; font-family: "Trebuchet MS"'
	);
	console.log(
		"%cI see you're interested in the code! I built this thing with React and GraphQL on Gatsby: https://www.gatsbyjs.org . I'm a full-stack growth marketer. If you're interested in working together, say hello here: https://www.linkedin.com/in/ruthdillonmansfield",
		'display: block; color: #c1002e; font-size: 15px; font-family: "Trebuchet MS"'
	);
};
